import { Subject } from 'rxjs';
import ContentfulIfuVersionInfo from '../models/contentful/ifu-version-info';

class AnalyticsService {
  analyticsReady = (window as any).analytics ? true : false;
  analyticsLoaded = new Subject<boolean>();

  constructor() {
    const interval = setInterval(() => {
      if (window && (window as any).analytics) {
        this.analyticsReady = true;
        this.analyticsLoaded.next(true);
        clearInterval(interval);
      }
    }, 50);
  }

  /**
   * Record any actions that the user performs, along with properties that describe the action.
   * @param eventName name of the event to track.
   * @param data the data associated with the event.
   */
  track(eventName: string, data?: any): void {
    data = data || {};
    (window as any).analytics.track(eventName, data);
  }

  trackViewInstrictions(ifuCode: string, selectedIfuVersion: ContentfulIfuVersionInfo, ifuVersionType: string): void {
    this.track('Instructions Viewed', {
      ifuCode,
      ifuVersionType,
      path: window.location.pathname,
      product: selectedIfuVersion.name,
      revision: selectedIfuVersion.revision,
    });
  }

  trackRequestsByMail(ifuCode: string, selectedIfuVersion: ContentfulIfuVersionInfo, ifuVersionType: string): void {
    this.track('Request By Mail Opened', {
      ifuCode,
      ifuVersionType,
      path: window.location.pathname,
      product: selectedIfuVersion.name,
      revision: selectedIfuVersion.revision,
    });
  }

  /**
   * Record whenever a user sees a page of the website.
   * @param title title of the page you are navigating to.
   */
  page(data: any): void {
    (window as any).analytics.page({
      ...data,
      url: window.location.href,
    });
  }
}

export default AnalyticsService;
