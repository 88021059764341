import { Link } from 'gatsby';
import React from 'react';
import { Modal } from 'react-bootstrap';
import contactSupport from '../pages/contact-support';
import AnalyticsService from '../services/analytics';
import Footer from './footer/footer';
import Header from './header/header';
import SVGS from './svgs/svgs';

interface IState {
  viewPopup: boolean;
  appLoading: boolean;
  isDentalProfessional: boolean;
}

class Layout extends React.Component<{ children: any }, IState> {
  Analytics: null | AnalyticsService = null;

  constructor(props: { children: any }) {
    super(props);
    this.state = {
      appLoading: true,
      isDentalProfessional: false,
      viewPopup: true,
    };
  }

  componentDidMount() {
    this.setState({
      appLoading: false,
    });
    const visited = sessionStorage.alreadyVisited;
    if (visited) {
      this.setState({ viewPopup: false });
    } else {
      sessionStorage.alreadyVisited = true;
      this.setState({ viewPopup: true });
    }
    this.Analytics = new AnalyticsService();
  }

  contactSupport() {
    this.Analytics!.track('Contact Support Clicked');
  }

  closeModal = () => this.setState({ viewPopup: false });
  handleCheckboxChange = () =>
    this.setState(this.state.isDentalProfessional ? { isDentalProfessional: false } : { isDentalProfessional: true });

  render() {
    if (this.state.appLoading) {
      return 'Loading...';
    }

    return (
      <div className="d-flex flex-column">
        <div className="layout-wrapper">
          <div className="svgs-wrap">
            <SVGS />
          </div>
          <Header />
          <main className={`py-3${location.pathname.includes('/contact-support') ? ' flex-block' : ''}`}>
            {this.props.children}
          </main>
          {!location.pathname.includes('/contact-support') && (
            <div className="flex-block pt-9">
              <div className="pt-5 pb-6 container">
                <h4>Not finding what you need?</h4>
                <p className="text-purple">Get in touch and we will help you out.</p>
                <Link className="link-plain btn btn-ifu" to="/contact-support">
                  Contact Support
                </Link>
              </div>
            </div>
          )}
          <Footer />
        </div>
        <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered={true} show={this.state.viewPopup}>
          <Modal.Header className="border-0 justify-content-center pb-0">
            <h4>Instructions for Use</h4>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="pb-2">
                This site is intended for dental professionals only. Please be aware that the content of the
                instructions for use can vary based on the regulatory requirements in your country.
              </div>

              <div className="pt-1 d-flex">
                <label className="has-custom-checkbox">
                  <input
                    type="checkbox"
                    id="disclaimer"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.isDentalProfessional}
                  />
                  <span className="custom-checkbox" />
                  <div className="small">
                    I hereby confirm that I am dental professional and I accept the
                    <a href="https://glidewelldental.com/privacy-policy" target="_blank">
                      {' '}
                      terms and conditions
                    </a>
                    for the use of this site
                  </div>
                </label>
              </div>
              <button
                className="btn btn-ifu w-100 mt-3"
                disabled={!this.state.isDentalProfessional}
                onClick={this.closeModal}>
                I Agree
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Layout;
