// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-line-category-tsx": () => import("../src/templates/product-line-category.tsx" /* webpackChunkName: "component---src-templates-product-line-category-tsx" */),
  "component---src-templates-product-instructions-info-tsx": () => import("../src/templates/product-instructions-info.tsx" /* webpackChunkName: "component---src-templates-product-instructions-info-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-support-confirmation-tsx": () => import("../src/pages/contact-support/confirmation.tsx" /* webpackChunkName: "component---src-pages-contact-support-confirmation-tsx" */),
  "component---src-pages-contact-support-index-tsx": () => import("../src/pages/contact-support/index.tsx" /* webpackChunkName: "component---src-pages-contact-support-index-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

