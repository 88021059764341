/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('./src/scss/main.scss');


// Wrap all the pages with Layout component so that it does not unmount on every page change.
exports.wrapPageElement = data => {
  const React = require('react');

  function preferDefault(m) {
    return (m && m['default']) || m;
  }

  exports.onRouteUpdate = ({ location, prevLocation }) => {
    // Call page every time a route is updated.
    window.analytics &&
      setTimeout(() =>
        window.analytics.page({
          name: document ? document.title : '',
          path: location.pathname,
          prevPath: prevLocation ? prevLocation.pathname : null,
          url: location.href,
          referrer: location.href,
        })
      );
  };

  let Layout;

  try {
    Layout = preferDefault(require('./src/components/layout.tsx'));
  } catch (e) {
    if (e.toString().indexOf('Error: Cannot find module') !== -1) {
      throw new Error(
        'Couldn\'t find layout component at "' +
          './src/components/layout.tsx' +
          '.\n\n' +
          'Please create layout component in that location or specify path to layout component in gatsby-config.js'
      );
    } else {
      // Logging the error for debugging older browsers as there is no way
      // to wrap the thrown error in a try/catch.
      console.error(e);
      throw e;
    }
  }

  // Pass prop to individual component that shouldn't have sticky header.
  return <Layout {...data.props}>{data.element}</Layout>;
};
