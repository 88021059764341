import React from 'react';
import styles from './footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={`${styles.footer} pt-5`}>
      <div className={styles.copyrightBlock}>
        <div className="container">
          <div className="d-sm-flex align-items-center justify-content-between">
            © {new Date().getFullYear()} Glidewell Laboratories. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
