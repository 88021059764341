import { Link } from 'gatsby';
import React from 'react';
import styles from './header.module.scss';

class Header extends React.Component {
  render() {
    const logo = `<use xlink:href="#glidewell-logo" />`;
    return (
      <header className={`${styles.headerWrap} py-2 mb-1`}>
        <div className="container">
          <Link to="/" className="link-plain company-logo mr-sm-7" aria-label="logo">
            <svg dangerouslySetInnerHTML={{ __html: logo }} />
          </Link>
        </div>
      </header>
    );
  }
}

export default Header;
